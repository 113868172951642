.medias-gallery {

    .section-container {
        $asideWidth: 300px;
        $gap: 15px;

        display: flex;
        gap: $gap;

        .gallery {
            width: calc(100% - $asideWidth - $gap);

            .gallery-container {
                display: flex;
                gap: 15px;
                flex-wrap: wrap;
                width: 100%;
        
                img {
                    width: calc(16.66% - 15px*5/6);
                    height: 7.5vw;
                    object-fit: cover;
                    cursor: pointer;
                    transition: all .1s;
                    outline: 4px solid transparent;
                    background-color: rgba(0, 0, 50, 0.15);
    
                    &.selected {
                        outline: 4px solid var(--primary);
                    }
                }
            }

            .pagination {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-evenly;
                list-style: none;
                margin-top: 20px;
            
                li {
                    cursor: pointer;
                    padding: 0 5px;
                    border-radius: 4px;
                    transition: .3s;
                    border-radius: 50%;
                    text-align: center;
                    padding: 0;

                    a {
                        width: 25px;
                        display: block;
                    }
            
                    &:hover, &.selected {
                        background-color: var(--primary);
                        color: #fff;
                    }

                    &.disabled {
                        opacity: .5;
                    }
                }
            }
        }
    
        .aside-right {
            width: $asideWidth;
            background-color: #fff;
            box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
            padding: 30px 20px;

            form {
                margin-top: 0;

                .row-form:first-child {
                    margin-top: 0;
                }

                .btn-container {
                    * + * {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

}