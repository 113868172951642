.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.grid-container-all{
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.card {
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  //height: 25rem;
  transition: box-shadow 0.3s ease;

  img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    width: 100%;
    height: 15rem;
    object-fit: cover;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    width: 100%;
    overflow-wrap: break-word;
  }
  div {
    padding: 20px;
  }
}

.text-input-conteneur {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  //height: 15rem;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  .standard-multiline-static{
    width: 20rem;
  }
}

.text-input {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  .standard-multiline-static{
    width: 20rem;
  }
}

.selected {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.chip-select {
  margin-left: 26%;
  margin-bottom: 1rem;
}

.info-send {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 5px;

  .info-send-title {
    font-size: 1.5rem;
  }

  .info-send-content {
    font-size: 1rem;
  }
}


.pageMovie {
  .ck.ck-editor {
    max-width: 360px;
  }
}