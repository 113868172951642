.table {
    box-shadow: 0 .46875rem 2.1875rem rgba(4,9,20,.03),0 .9375rem 1.40625rem rgba(4,9,20,.03),0 .25rem .53125rem rgba(4,9,20,.05),0 .125rem .1875rem rgba(4,9,20,.03);
    
    .MuiDataGrid-columnHeaders {
        background-color: #f6f6f6;
    }

    .MuiDataGrid-row {
        border-bottom: 1px solid #e1e4e8;
        background-color: #ffffff;
    }

}